import { FC } from "react";
import { Divider } from "../../../../styles/style.divider";

export const AppFeaturesSection: FC = () => {
  return (
    <section className="py-16 px-5 text-white space-y-24">
      <div className="px-4 flex flex-col">
        <div className="place-self-center justify-items-center mt-10">
          <h1 className="mb-16 max-w-4xl text-center text-4xl font-medium tracking-tight leading-none text-white">O <span className="text-amber-400 font-bold">guia</span> que faltava para tornar a vida do seu pet mais feliz</h1>
        </div>
      </div>

      <div className="grid grid-cols-12 items-center justify-items-center">
        <div className="lg:col-span-3 lg:col-start-3 col-span-12">
          <h1 className="text-2xl font-bold mb-3">Informações sobre o pet,<br /> Vacinas e Vermifugação</h1>
          <p className="text-xl lg:text-base text-white/80 mb-3">Diga adeus aos longos processos de cadastro! Agora, o Guia de Patas oferece um cadastro turbo de vacinas e vermifugos.
            <br />É tão rápido que até o coelho da Alice ficaria com inveja!</p>
          <a href="https://play.google.com/store/apps/details?id=br.com.codeinformatica.guiadepatas" rel="noreferrer"
            target="_blank" className="lg:text-sm text-lg  font-light text-amber-300">Quero conhecer</a>
        </div>

        <div className="lg:col-span-3 lg:col-end-10 lg:mt-0 mt-5 col-span-12">
          <div className="justify-center">
            <img src="https://storage.codeinformatica.com.br/features-home-app-landing.webp?version=4"
              className=" bg-purple-500/50 rounded-md border border-purple-500"
              loading="lazy" alt="mockup" width={880} />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 items-center justify-items-center">
        <div className="lg:col-span-3 lg:col-start-3 col-span-12">
          <div className="justify-center">
            <img src="https://storage.codeinformatica.com.br/features-ia-app-landing.webp?version=4"
              className=" bg-purple-500/50 rounded-md border border-purple-500"
              loading="lazy" alt="mockup" width={880} />
          </div>
        </div>

        <div className="col-span-12 lg:col-span-3 lg:col-end-10 mt-5 lg:mt-0">
          <h1 className="text-2xl font-bold mb-3">Guia virtual para cuidados com o pet</h1>
          <p className="text-xl lg:text-base text-white/80 mb-3">Abrimos as portas do conhecimento com o Peludinho IA. Uma biblioteca interativa com dicas úteis, truques mágicos e conselhos veterinários.
            <br />Este guia é como uma enciclopédia, mas mais fofa e sem cheiro de mofo!</p>
          <a href="https://play.google.com/store/apps/details?id=br.com.codeinformatica.guiadepatas" rel="noreferrer"
            target="_blank" className="lg:text-sm text-lg font-light text-amber-300">Quero conhecer</a>
        </div>

      </div>

      <div className="grid grid-cols-12 items-center justify-items-center">
        <div className="lg:col-span-3 lg:col-start-3 col-span-12">
          <h1 className="text-2xl font-medium mb-3">Lembrete de vacinas para peludos</h1>
          <p className="text-xl lg:text-base text-white/80 mb-3">Dizemos adeus às antigas notificações chatas! Agora, o lembrete de vacinas vem acompanhado de pompons virtuais. <br />Porque, afinal, manter a saúde em dia merece um toque de alegria!</p>
          <a href="https://play.google.com/store/apps/details?id=br.com.codeinformatica.guiadepatas" rel="noreferrer"
            target="_blank" className="lg:text-sm text-lg font-light text-amber-300">Quero conhecer</a>
        </div>

        <div className="lg:col-span-3 lg:col-end-10 col-span-12 lg:mt-0 mt-5">
          <div className="justify-center">
            <img src="https://storage.codeinformatica.com.br/features-lembretes-app-landing.webp?version=4"
              className=" bg-purple-500/50 rounded-md border border-purple-500"
              loading="lazy" alt="mockup" width={880} />
          </div>
        </div>
      </div>

      <Divider className="p-0 -mx-4" />
    </section>

  );
};
