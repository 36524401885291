import { FC, useState } from "react";
import { Button } from "../../../../styles/style.button";
import { ContactFormData, defaultFormData } from "../../../../components/ContactForm/types/index.";
import ContactForm from "../../../../components/ContactForm";

export const AboutSection: FC = () => {

    const [showContactForm, setShowContactForm] = useState<boolean>(false);

    const [sendingContactForm, setSendingContactForm] = useState<boolean>(false);
    const [contactFormModel, setContactFormModel] = useState<ContactFormData>(defaultFormData);

    return (
        <section id="sobre" className="pb-8">
            <div className="px-4 pb-12 flex flex-col">
                <div className="place-self-center justify-items-center mt-10">
                    <h1 className="mb-16 max-w-4xl text-center text-4xl font-medium tracking-tight leading-none text-white">Junte-se a comunidade <span className="text-amber-400 font-bold">Guia de Patas</span> e faça você também a vida dos pets uma vida melhor</h1>

                    <div className="mt-5 flex justify-center">
                        <Button onClick={() => setShowContactForm(true)} className="pt-8 justify-end">Entrar na lista de espera</Button>
                    </div>
                </div>
            </div>

            <ContactForm
                show={showContactForm}
                sending={[sendingContactForm, setSendingContactForm]}
                formData={[contactFormModel, setContactFormModel]}
                onClose={() => {
                    setShowContactForm(false);
                    setSendingContactForm(false);
                    setContactFormModel(defaultFormData);
                }}
            />
        </section>
    )
}