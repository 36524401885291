import { FC } from "react";
import { AboutSection } from "./sections/about";
import { AppFeaturesSection } from "./sections/features";
import { HeaderSection } from "./sections/header";
import { FooterSection } from "../footer";
import PrivacyPage from "../privacy";
export const HomePage: FC = () => (
  <>
    <HeaderSection />
    <AppFeaturesSection/>
    <PrivacyPage/>
    <AboutSection />
    <FooterSection />
  </>
);
