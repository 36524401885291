import { Routes, Route, Navigate } from "react-router-dom";
import NotFoundPage from "./404";
import { HomePage } from "./home";
import { MainLayout } from "./layouts/main";
import { GlobalStyle } from "./styles/GlobalStyle";

export const App = () => {

  return (
    <>
      <GlobalStyle />

      <Routes>
        <Route path="/" element={<MainLayout children={<HomePage />} />} />
        <Route path="*" element={<Navigate to="/404" />} />
        <Route path="/404" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};
