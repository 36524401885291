import { FC } from "react";
import { Divider } from "../../../../styles/style.divider";

export const HeaderSection: FC = () => {
  return (
    <section className="bg-purple-700">
      <div className="px-4 py-8 pt-24 lg:pt-36 flex flex-col">
        <div className="place-self-center justify-items-center mt-10">
          <h1 className="max-w-4xl text-center mb-4 text-4xl font-medium tracking-tight leading-none md:text-5xl xl:text-6xl text-white">O <span className="text-amber-400">guia</span> para cuidar do bem estar do seu pet</h1>
          <p className="max-w-4xl text-center mt-12 font-light text-gray-300 mb-4 text-3xl lg:text-xl">Assistente virtual para cuidar do seu pet, controle de vacinas, vermifugações e lembretes de medicamentos. Tudo isso na palma da sua mão.</p>

          <div className="flex justify-center pt-4 -py-4">
            <a href='https://play.google.com/store/apps/details?id=br.com.codeinformatica.guiadepatas&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              <img alt='Disponível no Google Play' width={180} src='https://play.google.com/intl/pt-BR/badges/static/images/badges/pt-br_badge_web_generic.png'/>
            </a>
          </div>
        </div>

        <div className="justify-center lg:flex py-0">
          <img src="https://storage.codeinformatica.com.br/guiadepatas-app-landing.webp?version=201" 
            loading="lazy" alt="mockup" width={880} />
        </div>

        <Divider className="p-0 -mx-4"/>
      </div>
    </section>
  );
};